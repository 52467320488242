import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { TelephoneNumberPanel } from "~/organisms/TelephoneNumberPanel";
import { FiDownload } from "@react-icons/all-files/fi/FiDownload";
import { useLocation } from "@reach/router";

type Props = {
  isTopPage?: Boolean;
};

export const Header: React.FC<Props> = ({ isTopPage = false }) => {
  const { logo } = useStaticQuery<GatsbyTypes.HeaderLogoQuery>(graphql`
    query HeaderLogo {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `);
  const location = useLocation();

  console.log(location.pathname);

  return (
    <>
      <style jsx>{`
        .wrapper {
          background-color: ${colors.white};
          padding: 20px 30px;
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          z-index: 100;
          box-shadow: 0 0 16px rgba(0, 0, 0, 0.04);
        }
        .container {
          align-items: center;
          display: flex;
        }
        .logo {
          display: block;
          height: 32px;
          width: 120px;
        }
        .navigation {
          margin-left: auto;
        }
        .navigationList {
          align-items: center;
          display: flex;
        }
        .navigationListItem {
          display: block;
        }
        .navigationListItem :global(a) {
          color: ${colors.defaultText};
          font-size: 14px;
          font-weight: bold;
          transition: all 0.2s ease-out;
          letter-spacing: 1px;
        }
        .navigationListItem :global(a:hover) {
          opacity: 0.8;
          text-decoration: underline;
        }
        .navigationListItem :global(a:visited) {
          color: ${colors.defaultText};
          font-size: 14px;
          font-weight: 500;
          transition: all 0.2s ease-out;
        }
        .navigationListItem :global(a .en) {
          font-size: 1.16em;
        }
        .navigationListItem + .navigationListItem {
          margin-left: 30px;
        }
        .cvButtonContainer {
          display: block;
        }
        .cvButtonContainer :global(a) {
          display: block;
          position: relative;
          align-items: center;
          background-image: ${colors.brandGradient};
          background-color: ${colors.brand};
          border-radius: 50px;
          display: flex;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          justify-content: center;
          padding: 6px 24px;
          height: 42px;
          position: relative;
          text-align: center;
          text-decoration: none;
          transition: all 0.2s ease-out;
        }
        .cvButtonContainer :global(a:hover) {
          opacity: 0.85;
          text-decoration: none;
          transform: translate3d(0, -1px, 0);
        }
        .cvButtonContainer :global(a:before) {
          background-color: ${colors.brandGradient};
          border-radius: 50px;
          content: "";
          left: 1px;
          height: calc(100% - 2px);
          position: absolute;
          top: 1px;
          width: calc(100% - 2px);
        }
        .cvButtonText {
          color: ${colors.white};
          display: block;
          position: relative;
          z-index: 1;
        }
        @media (max-width: 1135px) {
          .navigation {
            display: none;
          }
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            padding: 20px;
            box-shadow: none;
          }
          .navigation {
            display: none;
          }
        }
      `}</style>
      <header className="wrapper">
        <div className="container">
          {logo?.childImageSharp && (
            <Link to={`${location.pathname}${location.search}`}>
              <span className="logo">
                <GatsbyImage image={logo?.childImageSharp?.gatsbyImageData} alt="refcome" />
              </span>
            </Link>
          )}
          <nav className="navigation">
            <ul className="navigationList">
              {isTopPage && (
                <li className="navigationListItem">
                  <Link to={`${location.pathname}${location.search}#aboutReferral`}>リファラル採用とは</Link>
                </li>
              )}
              {isTopPage && (
                <li className="navigationListItem">
                  <Link to={`${location.pathname}${location.search}#whyChooseUs`}>選ばれる理由</Link>
                </li>
              )}
              <li className="navigationListItem">
                <Link to={isTopPage ? `${location.pathname}${location.search}#cases` : PATH.CASES.to}>導入事例</Link>
              </li>
              <li className="navigationListItem">
                <Link to={PATH.EVENTS.to}>イベント</Link>
              </li>
              <li className="navigationListItem">
                <Link to={PATH.INSIGHT.to}>
                  <span className="en">{PATH.INSIGHT.name}</span>
                </Link>
              </li>
              <li className="navigationListItem">
                <span className="cvButtonContainer">
                  <Link to={PATH.INQUIRY_CONTACT.to}>
                    <span className="cvButtonText">資料をダウンロード</span>
                    <FiDownload style={{ fontSize: "16px", marginLeft: "4px", color: `${colors.white}` }} />
                  </Link>
                </span>
              </li>
              <li className="navigationListItem">
                <TelephoneNumberPanel />
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};
